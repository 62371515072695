var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"cat-grid"},[(
            _vm.userData.role_name === 'admin' ||
            _vm.userData.role_name === 'establishment_admin' ||
            _vm.userData.role_name === 'store_clerk'
          )?_c('b-card',{staticClass:"cat",on:{"click":function($event){return _vm.$router.push({ name: 'store-products' })}}},[_c('div',{staticClass:"\n              h-100\n              d-flex\n              flex-column\n              justify-content-between\n              align-items-center\n            "},[_c('div',{staticClass:"text-center name"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_vm._v(" Ver inventario ")],1),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"FileTextIcon"}})],1)]):_vm._e(),(
            _vm.userData.role_name === 'admin' ||
            _vm.userData.role_name === 'establishment_admin' ||
            _vm.userData.role_name === 'store_clerk'
          )?_c('b-card',{staticClass:"cat",on:{"click":function($event){return _vm.$router.push({ name: 'add-store-products' })}}},[_c('div',{staticClass:"\n              h-100\n              d-flex\n              flex-column\n              justify-content-between\n              align-items-center\n            "},[_c('div',{staticClass:"mb-2 text-center name"},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Agregar producto o servicio ")],1),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"BoxIcon"}})],1)]):_vm._e(),(
            _vm.userData.role_name === 'admin' ||
            _vm.userData.role_name === 'establishment_admin' ||
            _vm.userData.role_name === 'store_clerk'
          )?_c('b-card',{staticClass:"cat",on:{"click":function($event){return _vm.$router.push({ name: 'store-variants' })}}},[_c('div',{staticClass:"\n              h-100\n              d-flex\n              flex-column\n              justify-content-between\n              align-items-center\n            "},[_c('div',{staticClass:"text-center name"},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Agregar variantes ")],1),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"ListIcon"}})],1)]):_vm._e(),(
            _vm.userData.role_name === 'admin' ||
            _vm.userData.role_name === 'establishment_admin' ||
            _vm.userData.role_name === 'store_clerk'
          )?_c('b-card',{staticClass:"cat",on:{"click":function($event){return _vm.$router.push({ name: 'get-store-promotions' })}}},[_c('div',{staticClass:"\n              h-100\n              d-flex\n              flex-column\n              justify-content-between\n              align-items-center\n            "},[_c('div',{staticClass:"text-center name"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_vm._v(" Promos ")],1),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"GiftIcon"}})],1)]):_vm._e(),(
            _vm.userData.role_name === 'admin' ||
            _vm.userData.role_name === 'establishment_admin' ||
            _vm.userData.role_name === 'store_clerk'
          )?_c('b-card',{staticClass:"cat",on:{"click":function($event){return _vm.$router.push({ name: 'store-automatic-restocking' })}}},[_c('div',{staticClass:"\n              h-100\n              d-flex\n              flex-column\n              justify-content-between\n              align-items-center\n            "},[_c('div',{staticClass:"text-center name"},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Pedidos automáticos ")],1),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"TruckIcon"}})],1)]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }